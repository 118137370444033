import React from "react";
import "./PuppyNews.css";
// import PuppyOne from "../../assets/pictures/valpnytt1.jpg";
// import PuppyTwo from "../../assets/pictures/valpnytt2.jpg";
import Macy from "../../assets/pictures/macy1.jpg";
import Macy2 from "../../assets/pictures/Macy2.jpg";

const PuppyNews = () => {
  return (
    <div className="root">
      <div className="containerr">
        {/* <div className="imagecontainer">
          <img src={PuppyOne} width={455} height={455} alt="valpnytt 1" />
          <label>SÖ Just call me Jingles SE43355/2017</label>
        </div>
        <div className="imagecontainer">
          <img src={PuppyTwo} width={455} height={455} alt="valpnytt 2" />
          <label>Lakkas Rubens SE21735/2018</label>
        </div> */}
      </div>
      {/* <p>
        Valpar födda 2 mars, leveransklara från 29 april. 7 tikar och 2
        hanvalpar, finns valp att tillgå fortfarande.
      </p> */}
      <div className="imagecontainer">
        <img src={Macy} width={555} height={455} alt="valpnytt 3" />
        <p>Maja SE55942/2021</p>
        <img src={Macy2} width={555} height={455} alt="valpnytt 3" />
        <p>Helix SE16408/2019</p>
      </div>
      <p>
        Planerar för valpar i sommar med Maja o Helix. Amerikanska pälsar men
        hanterbara.
      </p>
    </div>
  );
};

export default PuppyNews;
